import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ampli } from './ampli';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Gallery from "./screens/Gallery";
import Home from "./screens/Home";
import MixColor from "./screens/MixColor";
import { ensureUserId, getUserId } from "./storage";

ensureUserId();
// reset();

ampli.load({ environment: 'production' });

ampli.identify(getUserId()!, {
  browser_language: navigator.language || 'unknown',
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/mix-color",
    element: <MixColor />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
