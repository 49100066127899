import React from "react";
import "./ColorButton.css";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
}

export default function ColorButton({ color, children, ...rest }: Props) {
  return (
    <button
      className="ColorButton"
      style={{
        backgroundColor: color,
      }}
      {...rest}
    >
      {children}
    </button>
  );
}
