
export type RGBColor = [number, number, number];


export function storeColor(clr: RGBColor) {
  // First retrieve all the colors from local storage
  const colors = getColors();

  // Add the new color to the array
  colors.push(clr);

  // Store the new array of colors
  localStorage.setItem('my.colors', JSON.stringify(colors));

  // Return the new array of colors
  return colors;
}

export function getColors(): RGBColor[] {
  const colors = localStorage.getItem('my.colors');
  if (colors) {
    return JSON.parse(colors);
  }
  return [];
}


export function ensureUserId() {
  let userId = localStorage.getItem('my.userId');
  if (!userId) {
    userId = Math.random().toString(36).substring(2);
    localStorage.setItem('my.userId', userId);
  }
  return userId;
}

export function getUserId() {
  return localStorage.getItem('my.userId');
}


export function hasGivenFeedback() {
  return localStorage.getItem('my.feedback') === 'true';
}

export function setFeedbackGiven() {
  localStorage.setItem('my.feedback', 'true');
}

export function reset() {
  localStorage.clear();
}

// reset();