import FeedbackPopup from "../components/FeedbackPopup";
import GoBack from "../components/GoBack";
import { getColors, hasGivenFeedback } from "../storage";
import "./Gallery.css";

export default function Gallery() {

  const colors = getColors();

  return <div className="Gallery">
    <GoBack />
    <h1>Gallery</h1>
    <p>Your colors, side by side!</p>

    <div className="Gallery-Colors">
      {colors.map((color, index) => {
        const style = {
          backgroundColor: `rgb(${color.join(", ")})`,
          width: "100px",
          height: "100px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          margin: '4px'
        };

        return <div key={index} style={style} />;
      })}
    </div>

    {!hasGivenFeedback() && colors.length > 0 && <FeedbackPopup />}
  </div>;
}
