import ColorButton from "../components/ColorButton";
import React, { useCallback } from "react";
import "./MixColor.css";
import GoBack from "../components/GoBack";
import {  useNavigate} from 'react-router-dom'
import { RGBColor, storeColor } from "../storage";
import { ampli } from "../ampli";

const incrementSpeed = 15;

function MixColor() {
  // Only do this once
  const colorSessionId = React.useMemo(() => {
    return Math.random().toString(36).substring(2, 15);
  }, [])
  const [mixedColor, setMixedColor] = React.useState<RGBColor>([0, 0, 0]);
  const navigate = useNavigate();

  /**
   * Simply adds two RGB colors together.
   */
  const onColorClick = React.useCallback((color: RGBColor, buttonColor: string) => {    
    setMixedColor((prevColor) => {
      const [r, g, b] = prevColor;
      const [r2, g2, b2] = color;

      // Prevent going over 255
      const newR = Math.min(r + r2, 255);
      const newG = Math.min(g + g2, 255);
      const newB = Math.min(b + b2, 255);
      const newColor: RGBColor = [newR, newG, newB];

      ampli.modifyColor({
        button_color: buttonColor,
        current_color: newColor,
        session_id: colorSessionId,
      });

      return newColor;
    });
  }, [colorSessionId]);

  const onSubmit = useCallback(() => {
    // store the color
    storeColor(mixedColor);

    ampli.submitColor({
      session_id: colorSessionId,
      submitted_color: mixedColor,
    });

    // Then redirect to the gallery page
    navigate('/gallery');
  }, [mixedColor, colorSessionId]);

  const onReset = useCallback(() => {
    ampli.resetColor({
      session_id: colorSessionId,
    });
    setMixedColor([0, 0, 0]);
  }, [colorSessionId]);

  const currentColor = `rgb(${mixedColor.join(", ")})`;

  return (
    <div className="Screen">
      <GoBack />
      <div className="Mix">
        <h1>Mix colors to create your favorite color!</h1>
        <p>Click on the buttons to start mixing.</p>
        <ColorButton
          disabled={mixedColor[0] > 254}
          color="red"
          onClick={() => onColorClick([incrementSpeed, 0, 0], 'red')}
        >
          Mix Red
        </ColorButton>
        <ColorButton
          disabled={mixedColor[1] > 254}
          color="green"
          onClick={() => onColorClick([0, incrementSpeed, 0], 'green')}
        >
          Mix Green
        </ColorButton>
        <ColorButton
          disabled={mixedColor[2] > 254}
          color="blue"
          onClick={() => onColorClick([0, 0, incrementSpeed], 'blue')}
        >
          Mix Blue
        </ColorButton>

        <button className="Clear-Button" onClick={onReset}>Reset</button>
      </div>

      <div className="Result">
        <p>Current color:</p>
        <div
          style={{
            backgroundColor: currentColor,
            width: "100px",
            height: "100px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            marginBottom: "16px",
          }}
        />
        <button className="Clear-Button" onClick={onSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default MixColor;
