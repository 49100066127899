import React from "react";
import { ampli } from "../ampli";
import { setFeedbackGiven } from "../storage";
import "./FeedbackPopup.css";

export default function FeedbackPopup() {
  const [rating, setRating] = React.useState(0);
  const [hide, setHide] = React.useState(false);
  const [text, setText] = React.useState("");
  
  const onSubmit = React.useCallback(() => {
    ampli
      .rateExperience({
        experience_submitted: "Submitted",
        experience_evaluation: rating,
        experience_verbatim: text,
      })
      .promise.finally(() => {
        setFeedbackGiven();
        setHide(true);
      });
  }, [rating, text]);

  const onDismiss = React.useCallback(() => {
    ampli
      .rateExperience({
        experience_submitted: "Discarded",
        experience_evaluation: rating,
        experience_verbatim: text,
      })
      .promise.finally(() => {
        setFeedbackGiven();
        setHide(true);
      });
  }, [rating, text]);

  if (hide) {
    return null;
  }

  return (
    <div className="FeedbackPopup">
      <div className="FeedbackPopup-Content">
        <h1>Feedback</h1>
        <p>What did you think of the experience?</p>

        <div className="FeedbackPopup-Stars">
          <button
            onClick={() => setRating(1)}
            className="FeedbackPopup-Star"
            style={{ opacity: rating >= 1 ? 1 : 0.2 }}
          ></button>
          <button
            onClick={() => setRating(2)}
            className="FeedbackPopup-Star"
            style={{ opacity: rating >= 2 ? 1 : 0.2 }}
          ></button>
          <button
            onClick={() => setRating(3)}
            className="FeedbackPopup-Star"
            style={{ opacity: rating >= 3 ? 1 : 0.2 }}
          ></button>
          <button
            onClick={() => setRating(4)}
            className="FeedbackPopup-Star"
            style={{ opacity: rating >= 4 ? 1 : 0.2 }}
          ></button>
          <button
            onClick={() => setRating(5)}
            className="FeedbackPopup-Star"
            style={{ opacity: rating >= 5 ? 1 : 0.2 }}
          ></button>
        </div>

        <textarea
          className="FeedbackPopup-Textarea"
          placeholder="Your feedback"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <button disabled={!rating} className="Clear-Button" onClick={onSubmit}>
          Submit
        </button>
        <button className="Dismiss-Button" onClick={onDismiss}>
          X
        </button>
      </div>
    </div>
  );
}
