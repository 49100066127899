import { Link, Route } from "react-router-dom";
import { getUserId, reset } from "../storage";
import "./Home.css";

export default function Home() {
  return <div className="Home">
    <h1>Home</h1>

    <Link to='/gallery'>Show the gallery</Link>
    <Link to='/mix-color'>Mix your own color!</Link>
    
    {/* <button onClick={() => {
      reset();
      // Reload the page
      window.location.reload();
    }}>Reset all</button> */}

    <p>Your user_id: {getUserId()}</p>
  </div>;
}

